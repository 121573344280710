import React, { useEffect, useState } from 'react';
import { db } from '../../firebase.js';
import { ref, onValue, update } from 'firebase/database';
import '../../assets/sb-admin-2.css';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo_multi.svg';

const PopupQuotes = (props) => {
  const [quotes, setQuotes] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {}, [categories]);

  useEffect(() => {}, [quotes]);

  const getDatIHM = () => {
    onValue(ref(db, `/dataIHM/${quotes.categories}`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) setCategories(data);
    });
  };

  const addQuotes = () => {
    if (quotes.categories != undefined) {
      update(ref(db, `/dataIHM/${quotes.categories}/${categories.length}/`), {
        ...quotes,
        categories: quotes.categories[0] === 'relationel' ? ['relationnel'] : quotes.categories,
        like: 0,
        unlike: 0
      });
      props.deletePopup();
      window.location.reload();
    } else {
      alert('Veuillez ajouter une categorie');
    }
  };

  return (
    <Popup style={{ position: 'fixed' }}>
      <Layout onClick={() => props.deletePopup()}></Layout>
      <Modal>
        <form>
          <div className="form-group">
            <label style={{ fontSize: '17px', fontWeight: 'bold' }}>Catégorie*</label>
            <select
              className="form-control"
              value={quotes.categories}
              onChange={(e) => {
                setQuotes({ ...quotes, categories: [e.target.value] });
                setTimeout(() => {
                  getDatIHM();
                }, 1000);
              }}>
              <option>Selectionnez une categories</option>
              <option>relationel</option>
              <option>motivation</option>
              <option>finances</option>
              <option>mental</option>
              <option>amour</option>
              <option>familial</option>
              <option>organisation</option>
              <option>santé</option>
              <option>spiritualité</option>
              <option>professionnel</option>
            </select>
          </div>
          <div className="form-group">
            <label style={{ fontSize: '17px', fontWeight: 'bold' }}>Points*</label>
            <select
              className="form-control"
              value={quotes.point}
              onChange={(e) => {
                setQuotes({ ...quotes, point: e.target.value });
                setTimeout(() => {
                  getDatIHM();
                }, 1000);
              }}>
              <option disabled>Selectionnez une categories</option>

              <option>6</option>
              <option>8</option>
              <option>9</option>
              <option>12</option>
              <option>15</option>
              <option>17</option>
            </select>
          </div>
          <div className="form-group" onClick={() => getDatIHM()}>
            <label style={{ fontSize: '17px', fontWeight: 'bold' }}>Contenu du verset</label>
            <textarea
              className="form-control"
              rows="3"
              value={quotes.title}
              onChange={(e) => {
                setQuotes({ ...quotes, verseText: e.target.value });
              }}></textarea>
          </div>
          <div className="form-group" onClick={() => getDatIHM()}>
            <label style={{ fontSize: '17px', fontWeight: 'bold' }}>Titre verset</label>
            <input
              type="text"
              className="form-control"
              placeholder="Matthieu 23:12"
              value={quotes.verse}
              onChange={(e) => {
                setQuotes({ ...quotes, verse: e.target.value });
              }}
            />
          </div>
          <div className="form-group" onClick={() => getDatIHM()}>
            <label style={{ fontSize: '17px', fontWeight: 'bold' }}>Explication</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              alue={quotes.text}
              onChange={(e) => {
                setQuotes({ ...quotes, verseDescription: e.target.value });
              }}></textarea>
          </div>
          <div className="form-group" onClick={() => getDatIHM()}>
            <label style={{ fontSize: '17px', fontWeight: 'bold' }}>Challenge</label>
            <input
              type="text"
              className="form-control"
              placeholder="Dit à une personne que tu n'aime pas que tu l'aime aujourdhui"
              value={quotes.challenge}
              onChange={(e) => {
                setQuotes({ ...quotes, challenge: e.target.value });
              }}
            />
          </div>
        </form>
        <button className="btn btn-primary" onClick={() => addQuotes()}>
          Submit
        </button>
      </Modal>
    </Popup>
  );
};

export default function Dashboard() {
  const [quotes, setQuotes] = useState([]);
  const [popup, setPopup] = useState(false);
  const [text, setText] = useState([]);

  const [arrayUser, setArrayUser] = useState([]);
  const [indexCategories, setindexCategories] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {}, [text]);
  useEffect(() => {
    onValue(ref(db, `/dataIHM`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        setindexCategories(Object.keys(data));
        Object.values(data).map((todo) => {
          setText((oldArray) => [...oldArray, todo]);
        });
      }
    });
    onValue(ref(db, `/quotes`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        Object.values(data).map((todo) => {
          setQuotes((oldArray) => [...oldArray, todo]);
        });
      }
    });
    onValue(ref(db, `/users`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        Object.values(data).map((todo) => {
          setArrayUser((oldArray) => [...oldArray, todo]);
        });
      }
    });
  }, []);

  const deletePopup = () => {
    setPopup(false);
  };

  const navigateCategory = (category) => {
    navigate('/dashboard/' + category);
  };
  const numberCategories = () => {
    let number = 0;
    text.map((user) => {
      number = number + user.length;
    });
    return number;
  };

  const convertirValeurEnRange = (valeurActuelle, valeurMin, valeurMax, rangeMin, rangeMax) => {
    return (
      ((valeurActuelle - valeurMin) / (valeurMax - valeurMin)) * (rangeMax - rangeMin) + rangeMin
    );
  };

  return (
    <div>
      <div id="wrapper">
        {popup ? <PopupQuotes array={quotes} deletePopup={deletePopup} /> : ''}
        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* Main Content */}
          <div id="content">
            {/* Topbar */}
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 fixed-top shadow">
              {/* Sidebar Toggle (Topbar) */}

              {/* Topbar Navbar */}
              <ul className="navbar-nav ml-auto">
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: '150px', height: '50px', position: 'absolute', left: 10 }}
                />
                {/* Nav Item - Alerts */}
                <button
                  type="button"
                  style={{
                    height: '50px',
                    border: 'none',
                    alignSelf: 'center',
                    fontSize: '0.625rem',
                    padding: '10px',
                    fontWeight: '600',
                    borderRadius: '5px',
                    color: 'white',
                    backgroundColor: '#2654E3',
                    fontFamily: 'GalanoGrotesque-Medium'
                  }}
                  data-toggle="modal"
                  onClick={() => setPopup(true)}
                  data-target="#exampleModal">
                  Ajouter un verset
                </button>
                <div className="topbar-divider d-none d-sm-block" />
                {/* Nav Item - User Information */}
              </ul>
            </nav>
            {/* End of Topbar */}
            {/* Begin Page Content */}

            <div
              className="container-fluid"
              style={{
                paddingTop: '5rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                height: '100vh'
              }}>
              <div className="row">
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-secondary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            Objectif challenge tout Catégorie
                          </div>
                          <div className="row no-gutters align-items-center">
                            <div className="col-auto">
                              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                {convertirValeurEnRange(
                                  numberCategories(),
                                  0,
                                  3600,
                                  0,
                                  100
                                ).toFixed(2)}
                                %
                              </div>
                            </div>
                            <div className="col">
                              <div className="progress progress-sm mr-2">
                                <div
                                  className="progress-bar bg-secondary"
                                  role="progressbar"
                                  style={{
                                    width: `${convertirValeurEnRange(
                                      numberCategories(),
                                      0,
                                      3600,
                                      0,
                                      100
                                    ).toFixed(2)}%`
                                  }}
                                  aria-valuenow={convertirValeurEnRange(
                                    numberCategories(),
                                    0,
                                    3600,
                                    0,
                                    100
                                  ).toFixed(2)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-clipboard-list fa-2x text-gray-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                            Objectif 300 utilisateurs
                          </div>
                          <div className="row no-gutters align-items-center">
                            <div className="col-auto">
                              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                {convertirValeurEnRange(arrayUser.length, 0, 300, 0, 100).toFixed(
                                  2
                                )}
                                %
                              </div>
                            </div>
                            <div className="col">
                              <div className="progress progress-sm mr-2">
                                <div
                                  className="progress-bar bg-info"
                                  role="progressbar"
                                  style={{
                                    width: `${convertirValeurEnRange(
                                      arrayUser.length,
                                      0,
                                      300,
                                      0,
                                      100
                                    ).toFixed(2)}%`
                                  }}
                                  aria-valuenow={convertirValeurEnRange(
                                    arrayUser.length,
                                    0,
                                    300,
                                    0,
                                    100
                                  ).toFixed(2)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-clipboard-list fa-2x text-gray-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Page Heading */}
              <div className="row">
                {text.length > 0
                  ? text.map((user, index) => {
                      return (
                        <>
                          <div
                            className="col-xl-3 col-md-6 mb-4"
                            onClick={() => navigateCategory(indexCategories[index])}>
                            <div className="card border-left-primary shadow h-100 py-2">
                              <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                  <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      {indexCategories[index]}
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                      {user.length}
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <i className="fas fa-calendar fa-2x text-gray-300" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : 'Chargement..'}
              </div>
              {/* Content Row */}
              {/* Content Row */}
            </div>
            {/* /.container-fluid */}
          </div>
        </div>
        {/* End of Content Wrapper */}
      </div>
    </div>
  );
}

const Popup = styled.div`
  width: 100%;
  height: 100vh;
  position absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98
`;

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  position absolute;
  background: black;
  opacity: 0.5;
`;
const Modal = styled.div`
  min-width: 336px;
  width: 50%;
  height: auto;
  align-self: baseline;
  background: white;
  z-index: 100;
  padding: 6px;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
