import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import ImgLoading from '../assets/box_loading.svg';
import { JetContext } from '../context/UserContext.js';

const Loading = () => {
  const [Jets] = useContext(JetContext);
  return (
    <ViewContainer style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
      <ContainerCircle>{createCircles()}</ContainerCircle>
      <ContainerImg src={ImgLoading} />
      <ContainerText style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>
        <h4>Nous préparons pour vous des challenges adaptés pour vos besoins</h4>
      </ContainerText>
    </ViewContainer>
  );
};

export default Loading;

const ViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000000000;
`;

const ContainerCircle = styled.div`
  width: 310px;
  height: 310px;
  border-radius: 165.5px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const circleAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const Circle = styled.div`
  width: 310px;
  height: 310px;
  background: #e7bf87; /* Couleur du cercle */
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  animation: ${circleAnimation} 1.5s infinite ease-in-out;
`;

const createCircles = () => {
  const circles = [];

  for (let i = 0; i < 1; i++) {
    circles.push(<Circle key={i} style={{ animationDelay: `${i * 0.5}s` }} />);
  }

  return circles;
};

const ContainerImg = styled.img`
  width: 207px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ContainerText = styled.div`
  font-size: 18px;
  position: absolute;
  width: 82%;
  bottom: 27%;
  left: 9%;
  text-align: center;
`;
