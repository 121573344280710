import React, { useContext } from 'react';
import styled from 'styled-components';
import { JetContext } from '../context/UserContext.js';

const Modal = (props) => {
  const [Jets] = useContext(JetContext);
  return (
    <ModalContainer style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
      <h2 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>{props.text}</h2>
      <ContainerButton>
        <Button style={{ backgroundColor: '#D39E54' }} onClick={props.cancel}>
          Annuler
        </Button>
        <Button style={{ backgroundColor: '#C81625' }} onClick={props.delete}>
          Supprimer
        </Button>
      </ContainerButton>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  position: fixed;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  top: 0;
  left: 0;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    margin-bottom: 130px;
    text-align: center;
  }
`;

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Button = styled.button`
  width: 150px;
  height: 50px;
  border: none;
  color: white;
  border-radius: 7px;
  font-family: GalanoGrotesque-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
