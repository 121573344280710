import React from 'react';
import styled from 'styled-components';

const Notification = ({ show, notification, setShow }) => {
  return (
    <Container className={`notification-container ${show ? 'show' : ''}`}>
      <h5 className="notification-title">{notification.title}</h5>
      <p className="notification-body">{notification.body}</p>
      <button onClick={() => setShow(false)}>X</button>
    </Container>
  );
};

export default Notification;

const Container = styled.div`
  width: 338px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 50%;
  z-index: 100;
  transition: all 1s ease-in;
  transform: translate(-50%, -50%);
  background-color: #e4e4e4;
  z-index: 2000000;
  p {
    margin: 0;
  }
  button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
  }
`;
