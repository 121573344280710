import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import imgBox from '../../assets/box.png';
import Arrow from '../../assets/Arrow.svg';
import { db } from '../../firebase.js';
import { ref, update } from 'firebase/database';
import { useLocation } from 'react-router-dom';
import arrowImg from '../../assets/arrow_prompt.svg';

const API_KEY = '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';
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: 'system',
  content:
    "Explain things like you're talking to a software professional with 2 years of experience."
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [prompt, setPrompt] = useState('');
  const { state } = useLocation();
  const [title, setTitle] = useState('Que voulez vous me dire ?');
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    update(ref(db, `/sensors/${state?.id}/`), {
      promt: 'Chargement...',
      isTyping: true
    });
  }, []);

  useEffect(() => {
    const arrayFilter = messages.filter((word) => word.sender === 'user');
    if (arrayFilter.length > 2) {
      setMessages(arrayFilter.slice(1));
      const firstMessage = document.querySelector('.message');
      firstMessage.classList.add('fade-out');
      setTimeout(() => {
        firstMessage.remove();
      }, 1000);
      setMessages(messages);
    }
  }, [messages]);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: 'user'
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = '';
      if (messageObject.sender === 'ChatGPT') {
        role = 'assistant';
      } else {
        role = 'user';
      }
      return { role: role, content: messageObject.message };
    });

    setTitle('Regardez votre box...');

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    const apiRequestBody = {
      model: 'gpt-3.5-turbo',
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages // The messages from our chat with ChatGPT
      ]
    };

    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(apiRequestBody)
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: 'ChatGPT'
          }
        ]);
        update(ref(db, `/sensors/${state?.id}/`), {
          promt: data.choices[0].message.content
        });
        setTitle('Que voulez vous me dire ?');
        setIsTyping(false);
      });
  }

  const goBack = () => {
    update(ref(db, `/sensors/${state?.id}/`), {
      isTyping: false
    });
    window.history.back();
  };

  const filteredArray = messages.filter((word) => word.sender === 'user');

  return (
    <Container>
      <ButtonGoBack onClick={() => goBack()}>
        <img src={Arrow} alt="arrow" />
      </ButtonGoBack>
      <ContainerImage>
        <Circle />
        <img src={imgBox} alt="box" />
      </ContainerImage>

      <h1>{title}</h1>
      {/* Affichage des messages */}
      <ContainerMessage>
        {filteredArray.map((item, index) => (
          <Message key={index} className="message">
            {item.message}
          </Message>
        ))}
      </ContainerMessage>

      {/* Formulaire d'envoi de message */}
      <div
        style={{ alignSelf: 'baseline', width: '100%', marginTop: '5em' }}
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   setMessages([...messages, { text: e.target.message.value }]);
        //   //handleSend(e.target.message.value);
        //   e.target.message.value = '';
        // }}
      >
        <Input
          type="text"
          name="message"
          placeholder="Entrez un message"
          onChange={(e) => setPrompt(e.target.value)}
        />
        <Button type="submit" onClick={() => handleSend(prompt)} disabled={isTyping}>
          <img src={arrowImg} alt="arrow" style={{ width: '28px' }} />
        </Button>
      </div>
    </Container>
  );
};

export default Chat;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #2654e3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
  align-items: center;
  img {
    width: 250px;
    margin: 20px 0px;
    z-index: 10;
  }
  h1 {
    font-size: 30px !important;
    text-align: center;
    margin-top: 10px;
    color: white;
  }
`;

const ContainerMessage = styled.div`
  width: 100%;
  height: 216px;
  margin: 10px 0px;
`;

const ContainerImage = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 190px;
  }
`;

const Input = styled.input`
  width: 78vw;
  height: 60px;
  border-radius: 7px;
  background: #f1f5fe;
  border: none;
  margin-right: 10px;
  padding-left: 10px;
`;

const Button = styled.button`
  width: 55px;
  height: 60px;
  border-radius: 7px;
  background: #f1f5fe;
  border: none;
  &:disabled {
    background: #c4c4c4;
  }
`;

const Message = styled.div`
  width: 100%;
  height: auto;
  padding: 10px;
  font-size: 14px;
  background: #f1f5fe;
  margin-bottom: 6px;
  border-radius: 7px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  &.fade-out {
    opacity: 0;
  }
`;

const ButtonGoBack = styled.button`
  position: relative;
  top: 10px;
  left: 10px;
  background: #f1f5fe;
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: baseline;
  z-index: 100;
  img {
    width: 70%;
  }
`;

const Circle = styled.div`
  position: absolute;
  width: 250px;
  height: 250px;
  z-index: 1;
  background: #5081e8;
  border-radius: 50%;
  top: 0%;
  left: 17%;
  transform: translate(-50%, -50%);
  animation: pulse 2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;
