import React, { useContext } from 'react';
import styled from 'styled-components';

// Assets
import boxActiveIcon from '../assets/navbar/Box_active.svg';
import eventActiveIcon from '../assets/navbar/event_active.svg';
import userActiveIcon from '../assets/navbar/user_active.svg';
import userIcon from '../assets/navbar/user.svg';
import boxIcon from '../assets/navbar/box.svg';
import eventIcon from '../assets/navbar/event.svg';
import dashboardIcon from '../assets/navbar/dashboard.svg';
import dashboardActiveIcon from '../assets/navbar/dashboard_active.svg';
import logo from '../assets/logo.svg';
import { JetContext } from '../context/UserContext.js';

const Navbar = (props) => {
  const { navigation, dataUser, link_homepage, link_event, link_compte, link_dashboard } = props;
  const [Jets] = useContext(JetContext);

  const isBoxActive = navigation === 'box';
  const isEventActive = navigation === 'event';
  const isCompteActive = navigation === 'compte';
  const isDashboardActive = dataUser.email === 'arsene971@gmail.com';

  return (
    <NavbarStyle style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
      <li>
        <img src={logo} alt="Logo" style={{ width: '100px' }} />
      </li>
      <li
        onClick={link_homepage}
        style={{
          fontWeight: isBoxActive ? 'bold' : '',
          color: isBoxActive ? '#D39E54' : '#93979C'
        }}>
        {isBoxActive ? (
          <img src={boxActiveIcon} alt="Box Active" />
        ) : (
          <img src={boxIcon} alt="Box" />
        )}
        <p>Mes box</p>
      </li>
      <li
        onClick={link_event}
        style={{
          fontWeight: isEventActive ? 'bold' : '',
          color: isEventActive ? '#D39E54' : '#93979C'
        }}>
        {isEventActive ? (
          <img src={eventActiveIcon} alt="Event Active" />
        ) : (
          <img src={eventIcon} alt="Event" />
        )}
        <p>Événement</p>
      </li>
      <li
        onClick={link_compte}
        style={{
          fontWeight: isCompteActive ? 'bold' : '',
          color: isCompteActive ? '#D39E54' : '#93979C'
        }}>
        {isCompteActive ? (
          <img src={userActiveIcon} alt="User Active" />
        ) : (
          <img src={userIcon} alt="User" />
        )}
        <p>Compte</p>
      </li>
      {isDashboardActive && (
        <li
          onClick={link_dashboard}
          style={{
            fontWeight: isDashboardActive ? 'bold' : '',
            color: isDashboardActive ? '#D39E54' : '#93979C'
          }}>
          {isDashboardActive ? (
            <img src={dashboardActiveIcon} alt="Dashboard Active" />
          ) : (
            <img src={dashboardIcon} alt="Dashboard" />
          )}
          Dashboard
        </li>
      )}
    </NavbarStyle>
  );
};

export default Navbar;

const NavbarStyle = styled.ul`
  width: 100%;
  height: 100px;
  background: #f5f5f5;
  position: fixed;
  bottom: -17px;
  left: 0px;
  z-index: 100;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  li {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 5px;
    flex-direction: column;
    height: 55px;
    cursor: pointer;
    justify-content: space-between;
    p {
      margin: 0 !important;
    }

    &:nth-child(1) {
      flex-grow: 0;
      @media only screen and (min-width: 912px) {
        display: block;
        flex-grow: 4;
      }
      display: none;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      flex-grow: 0;
      @media only screen and (min-width: 912px) {
        flex-grow: 1;
      }
    }

    list-style: none;
  }
`;
