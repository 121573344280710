import React from 'react';
import styled from 'styled-components';

const Button = ({ text, bg, handleClick, textColor, isNextStep }) => {
  return (
    <ButtonContainer
      style={{ backgroundColor: isNextStep ? '#DDC19B' : bg, color: textColor }}
      disabled={isNextStep}
      onClick={handleClick}>
      {text}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 7px;
  font-family: GalanoGrotesque-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  border: none;
  text-align: center;
  h6 {
    margin: 0 !important;
  }
`;

export default Button;
