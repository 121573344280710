import React, { useEffect, useState, useContext } from 'react';
import { auth, db } from '../firebase.js';
import { useNavigate } from 'react-router-dom';
import { ref, onValue, update } from 'firebase/database';

import { motion } from 'framer-motion';

import Box from '../assets/box.png';
import Navbar from '../components/NavBar.js';

import styled from 'styled-components';
import { JetContext } from '../context/UserContext.js';

export default function Homepage() {
  const [listCard, setListCard] = useState([]);
  const [isClicked, setIsClicked] = useState(null);
  const [todos, setTodos] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [Jets, setJets] = useContext(JetContext);
  const navigate = useNavigate();
  const prefersDarkMode =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));

        // read user
        onValue(ref(db, `/users/${auth.currentUser.uid}`), (snapshot) => {
          setTodos([]);
          const data = snapshot.val();
          if (data !== null || data !== undefined) {
            setDataUser(data.user);
            console.log('<HOMEPAGE> GET USER DATA: ', Jets);

            onValue(ref(db, `/sensors/`), (snapshot) => {
              const dataSensors = snapshot.val();

              Object.values(data.user.sensors).map((todo) => {
                const listBox = Object.values(dataSensors).find((x) => x.id === todo.id);

                setTodos((oldArray) => [...oldArray, listBox]);
              });
            });
          }
        });
      } else if (!user) {
        navigate('/');
      }
    });
  }, []);

  useEffect(() => {
    console.log('dataUser', dataUser);
  }, [dataUser]);

  useEffect(() => {
    // VÉRIFIER SI L'UTILISATEUR A UNE SUBSCRIPTION
    const fetchCustomerId = async () => {
      // const stripe = new Stripe(
      //   'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
      // );
      // const subscriptions = await stripe.subscriptions.list();

      // const findSubscriptionCustomer = subscriptions.data.find(
      //   (x) => x.customer === dataUser.customerId
      // );

      setJets(() => ({
        user: dataUser,
        isDarkMode: prefersDarkMode
      }));
    };
    fetchCustomerId();
  }, [dataUser]);

  const listChallenge = () => {
    todos.forEach((todo) => {
      onValue(ref(db, `/dataIHM/${todo?.category}/${todo?.numberChallenge}`), (snapshot) => {
        const data = snapshot.val();
        if (data !== null || data !== undefined) {
          setListCard((prevListCard) => {
            // Vérifier si l'objet existe déjà dans prevListCard
            const isObjectExist = prevListCard.some(
              (item) =>
                item.challenge === data?.challenge &&
                item.category === todo?.category &&
                item.id === todo?.id &&
                item.numberChallenge === todo?.numberChallenge
            );

            // Si l'objet n'existe pas, l'ajouter à prevListCard
            if (!isObjectExist) {
              return [
                ...prevListCard,
                {
                  challenge: data?.challenge,
                  category: todo?.category,
                  id: todo?.id,
                  numberChallenge: todo?.numberChallenge
                }
              ];
            }

            // Si l'objet existe déjà, retourner prevListCard sans modification
            return prevListCard;
          });
        }
      });
    });
  };

  useEffect(() => {
    listChallenge();
  }, [todos]);

  const handleClickCard = (category, number, id, likeType) => {
    setIsClicked(id);
    let numberLike = 0;
    if (likeType === 'like') {
      update(ref(db, `/dataIHM/${category}/${number}`), {
        like: (numberLike += 1)
      });
    } else {
      update(ref(db, `/dataIHM/${category}/${number}`), {
        unlike: (numberLike += 1)
      });
    }
  };

  const moveTo = () => {
    navigate('/add');
  };

  const pageMotion = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: 20, transition: { duration: 0.5 } }
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <ViewContainer
        className="container_home"
        style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
        {/* <ButtonGoBack onClick={handleSignOut}>OUT</ButtonGoBack> */}
        <Text>
          <h2 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>
            Défi du jour
            {dataUser.subscription ? '⭐️' : ''}
          </h2>
        </Text>
        <CardContainer>
          {listCard.map((item, key) => {
            return (
              <Card key={key}>
                <div>
                  <h4>{item.category}</h4>
                  <h4>{item.id}</h4>
                </div>
                <div
                  className="container_img left"
                  onClick={() =>
                    handleClickCard(item.category, item.numberChallenge, key + 'like', 'like')
                  }>
                  <svg
                    width="27"
                    height="23"
                    viewBox="0 0 27 23"
                    fill={isClicked === key + 'like' ? '#3F76E8' : 'none'}
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.05682 11.3602L12.6036 5.54594V1.37797C12.6036 1.187 12.741 1.01749 12.9464 0.953136L9.05682 11.3602ZM9.05682 11.3602C9.01401 11.4271 8.70806 11.5272 8.42688 11.5449L9.05682 11.3602ZM25.7078 12.391L25.3509 12.7829L25.7629 13.1164C26.1247 13.4091 26.4999 13.8727 26.4999 14.5574C26.4999 15.2514 26.0164 15.7277 25.4983 15.9995L25.1126 16.2018L25.2602 16.6117C25.3823 16.9509 25.4655 17.4591 25.1889 18.0059C25.0207 18.3384 24.8709 18.5703 24.6962 18.7449C24.5284 18.9126 24.3182 19.0465 24.0016 19.1483L23.5325 19.2991L23.6765 19.7704C23.8099 20.207 23.8808 20.7952 23.5894 21.2918C23.318 21.7543 22.7594 22.1173 21.786 22.279L21.7843 22.2793C21.7825 22.2796 21.78 22.2799 21.7769 22.2801C21.7753 22.2802 21.7734 22.2802 21.7713 22.2802H10.9505C10.9431 22.2802 10.9395 22.2795 10.9393 22.2795L10.9353 22.2787C10.8094 22.2545 10.1752 22.1233 9.5643 21.8311C8.91917 21.5224 8.49345 21.1296 8.44395 20.6668L8.44395 20.6667C8.38291 20.0969 8.37977 18.0299 8.39485 16.0177C8.40883 14.1531 8.43759 12.3875 8.44326 12.0455C8.44669 12.0449 8.45062 12.0444 8.45507 12.0441L8.45507 12.0441L8.45824 12.0439C8.64163 12.0324 8.82678 11.995 8.97678 11.9494C9.05161 11.9266 9.12907 11.8984 9.19903 11.8642C9.23378 11.8473 9.27543 11.8247 9.31707 11.7953C9.35183 11.7708 9.42173 11.7176 9.47792 11.6298L9.47802 11.6299L9.48367 11.6206L13.0305 5.80633L13.1036 5.68641V5.54594V1.42794C13.1627 1.41066 13.6179 1.2832 14.2075 1.2832C14.7534 1.2832 15.4588 1.39122 16.065 1.82058C16.6544 2.23808 17.2182 3.00767 17.4078 4.47033L17.4078 4.47037C17.6841 6.60069 17.4456 7.79355 17.2125 8.4047L16.9525 9.08663L17.6823 9.08285L24.155 9.04931C24.172 9.05045 24.1854 9.05098 24.1914 9.05125C24.1923 9.0513 24.1931 9.05133 24.1938 9.05137C24.6248 9.10805 25.2249 9.28545 25.6774 9.60768C26.1136 9.91835 26.3729 10.3253 26.2964 10.9011C26.1887 11.7117 25.9237 12.1539 25.7078 12.391Z"
                      stroke="black"
                    />
                    <path
                      d="M0.515448 11.1528H6.87112C6.87694 11.1528 6.882 11.1533 6.88633 11.1541V22.2791C6.882 22.2798 6.87694 22.2803 6.87112 22.2803H0.515448C0.509528 22.2803 0.504388 22.2798 0.5 22.279V11.1541C0.504388 11.1533 0.509528 11.1528 0.515448 11.1528Z"
                      stroke="black"
                    />
                  </svg>
                </div>
                <div
                  className="container_img right"
                  onClick={() =>
                    handleClickCard(item.category, item.numberChallenge, key + 'unlike', 'unlike')
                  }>
                  <svg
                    width="27"
                    height="23"
                    viewBox="0 0 27 23"
                    fill={isClicked === key + 'unlike' ? '#C81625' : 'none'}
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9432 12.2032L14.3964 18.0175V22.1855C14.3964 22.3765 14.259 22.546 14.0536 22.6103L17.9432 12.2032ZM17.9432 12.2032C17.986 12.1364 18.2919 12.0362 18.5731 12.0186L17.9432 12.2032ZM1.29222 11.1724L1.64914 10.7806L1.23709 10.4471C0.875338 10.1544 0.500141 9.69081 0.500141 9.00606C0.500141 8.31203 0.983555 7.83579 1.50166 7.56402L1.88742 7.36166L1.73982 6.95182C1.61767 6.61263 1.53447 6.1044 1.81106 5.55758C1.97934 5.22506 2.12913 4.99314 2.30384 4.81857C2.47163 4.65091 2.68184 4.51701 2.99841 4.4152L3.46749 4.26434L3.32351 3.7931C3.19011 3.3565 3.11917 2.76828 3.41055 2.27166C3.68202 1.80915 4.24057 1.4462 5.21396 1.28449L5.21565 1.2842C5.21753 1.28388 5.21995 1.28358 5.22308 1.28341C5.22475 1.28331 5.22661 1.28326 5.22871 1.28326H16.0495C16.0569 1.28326 16.0605 1.28394 16.0607 1.284L16.0647 1.28476C16.1906 1.30898 16.8248 1.44018 17.4357 1.73243C18.0808 2.04105 18.5065 2.43386 18.556 2.89665L18.5561 2.89673C18.6171 3.46655 18.6202 5.5336 18.6051 7.54574C18.5912 9.41033 18.5624 11.1759 18.5567 11.518C18.5533 11.5186 18.5494 11.5191 18.5449 11.5194L18.5449 11.5194L18.5418 11.5196C18.3584 11.5311 18.1732 11.5685 18.0232 11.6141C17.9484 11.6369 17.8709 11.6651 17.801 11.6992C17.7662 11.7162 17.7246 11.7388 17.6829 11.7681C17.6482 11.7927 17.5783 11.8459 17.5221 11.9337L17.522 11.9336L17.5163 11.9428L13.9695 17.7571L13.8964 17.8771V18.0175V22.1355C13.8373 22.1528 13.3821 22.2803 12.7925 22.2803C12.2466 22.2803 11.5412 22.1723 10.935 21.7429C10.3456 21.3254 9.78185 20.5558 9.59222 19.0931L9.59222 19.0931C9.31589 16.9628 9.55443 15.7699 9.78749 15.1588L10.0475 14.4768L9.31771 14.4806L2.84503 14.5142C2.828 14.513 2.81457 14.5125 2.80861 14.5122C2.80771 14.5122 2.80692 14.5121 2.80622 14.5121C2.37515 14.4554 1.77513 14.278 1.32263 13.9558C0.886353 13.6451 0.627094 13.2382 0.703598 12.6624C0.811333 11.8518 1.0763 11.4095 1.29222 11.1724Z"
                      stroke="black"
                    />
                    <path
                      d="M26.4846 12.4106H20.1289C20.1231 12.4106 20.118 12.4102 20.1137 12.4094V1.28441C20.118 1.28367 20.1231 1.28318 20.1289 1.28318H26.4846C26.4905 1.28318 26.4956 1.28368 26.5 1.28445V12.4094C26.4956 12.4101 26.4905 12.4106 26.4846 12.4106Z"
                      stroke="black"
                    />
                  </svg>
                </div>
                <h2 onClick={() => navigate(`/challenge/${item.category}/${item.numberChallenge}`)}>
                  {item.challenge}
                </h2>
              </Card>
            );
          })}
        </CardContainer>
        <h2 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Mes Box's</h2>
        <Sensors>
          {todos ? (
            todos.map((item, key) => {
              return (
                <Sensor
                  key={key}
                  onClick={() => navigate(`/sensors/${item.id}`)}
                  style={{ backgroundColor: Jets.isDarkMode ? '#323232' : '#FCF2E4' }}>
                  <img src={Box} alt="" />
                  <h4 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>{item.id}</h4>
                </Sensor>
              );
            })
          ) : (
            <></>
          )}
          <AddSensors onClick={moveTo}>
            <h4>Ajouter une box</h4>
          </AddSensors>
        </Sensors>

        <Navbar
          link_homepage={() => navigate('/homepage')}
          link_event={() => navigate('/event')}
          link_compte={() => navigate('/settings')}
          link_dashboard={() => navigate('/dashboard')}
          dataUser={dataUser}
          navigation="box"
        />
      </ViewContainer>
    </motion.div>
  );
}

const ViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  padding: 10px;
  @media only screen and (min-width: 912px) {
    padding-top: 90px;
    ul {
      position: fixed;
      top: 0px;
      background: none;
    }
  }
`;

const CardContainer = styled.ul`
  display: flex;
  width: 100%;
  gap: 12px;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  list-style: none;
  counter-reset: li;
`;

const Card = styled.li`
  width: 293px;
  height: 346.256px;
  border-radius: 31px;
  position: relative;
  background: linear-gradient(157deg, #fcf2e4 20.54%, #f1d3aa 96.6%);
  scroll-snap-align: start;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px;
  .container_img {
    position: absolute;
  }
  .left {
    bottom: 20px;
    left: 20px;
  }
  .right {
    bottom: 20px;
    left: 70px;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h4 {
      color: #d39e54;
    }
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  span {
    width: 44px;
    height: 44px;
    background: #36b982;
    border-radius: 50%;
    margin-right: 10px;
  }
  h2 {
    margin: 20px 0px;
  }
`;

const Sensors = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (min-width: 912px) {
    justify-content: flex-start;
  }
`;

const Sensor = styled.div`
  width: 156px;
  height: 161px;
  position: relative;
  background: #f1f5fe;
  border-radius: 7px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  img {
    width: 100px;
  }
`;

const AddSensors = styled.div`
  width: 156px;
  height: 161px;
  border-radius: 7px;
  background: #d39e54;
  color: white;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
