import React from 'react';
import styled from 'styled-components';

const ProgressBar = ({ progress }) => {
  return (
    <ProgressBarContainer>
      <Bar progress={progress} />
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  width: 158px;
  height: 9px;
  border-radius: 6px;
  background-color: #fcf2e4;
  position: absolute;
  top: 26px;
  left: 50%;
  transform: translateX(-50%);
`;

const Bar = styled.div`
  width: ${(props) => props.progress}%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--primary-color);
  transition: width 0.5s ease-in-out;
`;

export default ProgressBar;
