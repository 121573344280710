import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from 'stripe';
import styled from 'styled-components';
import checkImg from '../../assets/check.svg';
import Arrow from '../../assets/Arrow.svg';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      'pk_test_51MgTATKww5u0tgAGl6tKRYiuUtQeJQ0VS3lqAbPAaNhlxgGP6l3hgwSSkm75lQnMmCnwxmEWpOiItixT5v9lsTB300pMgY62Aj'
    );
  }

  return stripePromise;
};

const Checkout = () => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const item = {
    price: 'price_1N0NZ7Kww5u0tgAGYoF7qifw',
    quantity: 1
  };

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchCustomerId = async () => {
      const stripe = new Stripe(
        'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
      );
      const customer = await stripe.customers.search({
        query: "email:'jeanne@gmail.com'"
      });
      setCustomerId(customer);
    };

    console.log('customerId', customerId);

    fetchCustomerId();
  }, []);

  const checkoutOptions = {
    lineItems: [item],
    mode: 'subscription',
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`
  };

  const redirectToCheckout = async () => {
    setLoading(true);
    console.log('redirectToCheckout');

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log('Stripe checkout error', error);
    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);

  return (
    <Container>
      <ButtonGoBack onClick={() => goBack()}>
        <img src={Arrow} alt="" />
      </ButtonGoBack>
      <h1>Abonnement</h1>
      <Wrapper>
        <h2>
          17<span>€/mois</span>
        </h2>
        <h4>
          Découvrez notre abonnement premium exclusif qui enrichira votre expérience de méditation !
        </h4>
        <h4>Cette abonnement inclus :</h4>
        <div>
          <img src={checkImg} alt="" />
          <h6>Accès illimité à toutes nos catégories pour méditer en toute sérénité.</h6>
        </div>
        <div>
          <img src={checkImg} alt="" />
          <h6>
            Profitez d'avantages spéciaux tels que des soirées uniques et des séances de méditation
            en groupe.
          </h6>
        </div>
        <div>
          <img src={checkImg} alt="" />
          <h6>
            Engagez des discussions avec notre IA dédiée pour méditer en toute quiétude et obtenir
            des réponses à vos questions.{' '}
          </h6>
        </div>
        <div>
          <img src={checkImg} alt="" />
          <h6>Recevez gratuitement des retours sur votre box, avec une livraison incluse.</h6>
        </div>
        <Button className="checkout-button" onClick={redirectToCheckout} disabled={isLoading}>
          {isLoading ? 'Loading...' : "M'abonner"}
        </Button>
      </Wrapper>
    </Container>
  );
};

export default Checkout;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #2654e3;
  h1 {
    padding: 20px;
    color: white;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 91vh;
  background-color: white;
  border-radius: 37px 37px 0px 0px;
  padding: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  h2 {
    font-size: 50px !important;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    span {
      font-size: 20px;
    }
  }
  h4 {
    text-align: center;
    margin-top: 20px;
  }
  div {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    h6 {
      font-weight: 400 !important;
      font-size: 14px !important;
    }
  }
`;

const Button = styled.button`
  width: 100%;
  height: 54px;
  background: #2654e3;
  border-radius: 7px;
  font-size: 20px;
  margin: 10px 0px;
  border: none;
  color: white;
  margin-top: 3.5em;
`;

const ButtonGoBack = styled.button`
  position: absolute;
  top: 19px;
  left: 10px;
  z-index: 1000000;
  cursor: pointer;
  background: #f1f5fe;
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
`;
