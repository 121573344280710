import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/NavBar';
import { signOut } from 'firebase/auth';
import { auth, db } from '../../firebase.js';
import { ref, onValue, update } from 'firebase/database';
import Stripe from 'stripe';
import iconLogout from '../../assets/icon-logout.svg';
import { JetContext } from '../../context/UserContext.js';

import Modal from '../Modal';

export default function SettingUserpage() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [idSubscription, setIdSubscription] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [Jets] = useContext(JetContext);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('🚀 ~ file: SettingsUser.js:18 ~ SettingUserpage ~ paymentDate:', paymentDate);
        // read user
        onValue(ref(db, `/users/${auth.currentUser.uid}`), (snapshot) => {
          const data = snapshot.val();
          if (data !== null) {
            setUserData(data.user);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    // VÉRIFIER SI L'UTILISATEUR A UNE SUBSCRIPTION
    const fetchCustomerId = async () => {
      const stripe = new Stripe(
        'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
      );
      const subscriptions = await stripe.subscriptions.list();

      const findSubscriptionCustomer = subscriptions.data.find(
        (x) => x.customer === userData.customerId
      );
      setIdSubscription(findSubscriptionCustomer.id); // SET ID DE L'ABONNEMENT
      setPaymentDate(findSubscriptionCustomer.current_period_end); // MISE A JOUR DE LA DATE DE PAIEMENT
    };
    fetchCustomerId();
  }, [userData]);

  async function cancelSubscription(subscriptionId) {
    const stripe = new Stripe(
      'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
    );
    try {
      const subscription = await stripe.subscriptions.update(subscriptionId, {
        cancel_at_period_end: true
      });
      console.log("L'abonnement a été résilié avec succès :", subscription.id);
      return subscription;
    } catch (error) {
      console.error("Une erreur est survenue lors de la résiliation de l'abonnement :", error);
      throw error;
    }
  }

  // async function reactivateSubscription(subscriptionId) {
  //   const stripe = new Stripe(
  //     'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
  //   );
  //   try {
  //     const subscription = await stripe.subscriptions.update(subscriptionId, {
  //       cancel_at_period_end: false
  //     });
  //     console.log("L'abonnement a été réactivé avec succès :", subscription.id);
  //     return subscription;
  //   } catch (error) {
  //     console.error("Une erreur est survenue lors de la réactivation de l'abonnement :", error);
  //     throw error;
  //   }
  // }

  const desactiveSubscrition = () => {
    console.log('desactiveSubscrition customer', idSubscription, userData.customerId);
    // Utilisation de la fonction cancelSubscription pour résilier l'abonnement

    if (idSubscription !== '') {
      cancelSubscription(idSubscription)
        .then((subscription) => {
          console.log("L'abonnement a été résilié avec succès :", subscription.id);
          update(ref(db, `/users/${auth.currentUser.uid}/user`), {
            subscription: false
          });
          setModal(false);
        })
        .catch((error) => {
          console.error("Une erreur est survenue lors de la résiliation de l'abonnement :", error);
        });
    }
  };

  // const activeSubscription = () => {
  //   console.log('subsrcition customer', idSubscription, userData.customerId);
  //   // Utilisation de la fonction reactivateSubscription pour réactiver l'abonnement
  //   if (idSubscription !== '')
  //     reactivateSubscription(idSubscription)
  //       .then((subscription) => {
  //         console.log("L'abonnement a été réactivé avec succès :", subscription.id);
  //         update(ref(db, `/users/${auth.currentUser.uid}/user`), {
  //           subscription: true
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Une erreur est survenue lors de la réactivation de l'abonnement :", error);
  //       });
  // };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // const showModal = () => {
  //   setModal(true);
  // };

  return (
    <ViewContainer style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
      {modal && (
        <Modal
          cancel={() => setModal(false)}
          delete={() => desactiveSubscrition()}
          text="Voulez vous supprimer votre abonnemnt cela impactera tout les boxs configurer"
        />
      )}
      <h1 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Paramètre</h1>
      <ButtonGoBack onClick={handleSignOut}>
        <img src={iconLogout} alt="icon-logout" />
      </ButtonGoBack>
      <ContainerInput>
        <label style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Email</label>
        <TextInput value={auth ? auth.currentUser?.email : ''} disabled />
      </ContainerInput>
      <ContainerInput>
        <label style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Nom</label>
        <TextInput value={userData.nameUser} disabled />
      </ContainerInput>
      {userData.nextDatePayment !== undefined && (
        <ContainerInput>
          <label style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>
            {userData.subscription ? 'Prochain prélévement' : 'Derniere prélévement'}
          </label>
          <TextInput value={userData.nextDatePayment} disabled={true} />
        </ContainerInput>
      )}

      {userData.subscription === true ? (
        <>
          <ContainerInput>
            <label>Abonnement</label>
            <TextInput value="17/mois" disabled={true} />
          </ContainerInput>
        </>
      ) : (
        ''
      )}

      {/* {userData.nextDatePayment !== undefined && (
        <Button
          onClick={() => {
            userData.subscription ? showModal() : activeSubscription();
          }}
          style={{ backgroundColor: userData.subscription ? '#C81625' : '#D39E54' }}>
          {userData.subscription ? 'Résilier mon abonement' : 'Réactivé mon abonement'}
        </Button>
      )} */}

      <span style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>
        <p>Version 2.2.2</p>
      </span>

      <Navbar
        link_homepage={() => navigate('/homepage')}
        link_event={() => navigate('/event')}
        link_compte={() => navigate('/settings')}
        link_dashboard={() => navigate('/dashboard')}
        dataUser=""
        navigation="compte"
      />
    </ViewContainer>
  );
}

const ViewContainer = styled.div`
  height: 96vh;
  width: 100vw;
  position: relative;
  padding: 10px;
  padding-bottom: 7em;
  h2 {
    margin: 20px 0px;
  }
  @media only screen and (min-width: 912px) {
    padding: 100px 30%;
    ul {
      position: fixed;
      top: 0px;
      background: none;
    }
  }
  span {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const ContainerInput = styled.div`
  margin: 10px 0px;
  label {
    font-family: GalanoGrotesque-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
`;
// const Button = styled.button`
//   width: 100%;
//   height: 54px;
//   background: #d39e54;
//   border-radius: 7px;
//   font-size: 20px;
//   margin: 10px 0px;
//   border: none;
//   color: white;
//   font-family: GalanoGrotesque-Medium;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   &:disabled {
//     background-color: #cccccc;
//   }
// `;
const TextInput = styled.input`
  width: 100%;
  height: 52px;
  border: none;
  background: #fcf2e4;
  border-radius: 7px;
  font-size: 16px;
  margin: 10px 0px;
  padding-left: 5px;
  font-family: GalanoGrotesque-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  &:disabled {
    background-color: #cccccc;
  }
`;

const ButtonGoBack = styled.button`
  position: absolute;
  top: 19px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  background: #fcf2e4;
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  @media only screen and (min-width: 912px) {
    top: 119px;
  }
`;
