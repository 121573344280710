import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import Button from '../components/Button';
import Input from '../components/Input';
import ArrowButton from '../components/ArrowButton';

const SignUpContainer = ({
  goBack,
  handleChangeEmail,
  handleChangePassword,
  valueEmail,
  valuePassword,
  handleRegister,
  isValidEmail,
  isValidPassword
}) => {
  return (
    <Container>
      <ArrowButton color="white" handleClick={goBack} />
      <div className="first-block">
        <img src={logo} alt="logo" />
      </div>
      <div className="second-block">
        <div style={{ marginBottom: '30px' }}>
          <label>
            <h6>Email</h6>
          </label>
          <Input
            type="text"
            handleChange={handleChangeEmail}
            value={valueEmail}
            isValid={isValidEmail}
          />
        </div>
        <div>
          <label>
            <h6>Password</h6>
          </label>
          <Input
            type="password"
            handleChange={handleChangePassword}
            value={valuePassword}
            isValid={isValidPassword}
          />
        </div>
        <Button text="Crée un compte" bg="#D39E54" textColor="white" handleClick={handleRegister} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;

  .first-block {
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .second-block {
    height: 65%;
    width: 100%;
    background-color: white;
    padding: 20px 20px 0px 20px;
    label {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      font-family: GalanoGrotesque-SemiBold;
    }
  }
`;

export default SignUpContainer;
