import React, { useEffect, useState } from 'react';
import { db } from '../../firebase.js';
import { ref, update, remove, onValue } from 'firebase/database';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Card from '../../components/Card.js';
import Modal from '../Modal.js';
import { useNavigate } from 'react-router-dom';
import ArrowImg from '../../assets/Arrow.svg';

const DashboardDetails = () => {
  let { category } = useParams();
  const [dataCategory, setDataCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const navigate = useNavigate();

  const EditElement = (element, index, close, category) => {
    const [formData, setFormData] = useState({
      verse: element.element.verse,
      description: element.element.verseDescription,
      text: element.element.verseText,
      challenge: element.element.challenge
    });

    useEffect(() => {}, [element, close, index, category]);

    const updateData = () => {
      update(ref(db, `/dataIHM/${element.category}/${element.index}`), {
        challenge: formData.challenge,
        verse: formData.verse,
        verseDescription: formData.description,
        verseText: formData.text
      });
      window.location.reload();
    };

    return (
      <>
        <PopupEdit>
          <Popup>
            <form>
              <div className="form-group">
                <label>Verset</label>
                <textarea
                  className="form-control"
                  rows="3"
                  value={formData.text}
                  onChange={(e) => {
                    setFormData({ ...formData, text: e.target.value });
                  }}></textarea>
              </div>
              <div className="form-group">
                <label>Titre verset</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Matthieu 23:12"
                  value={formData.verse}
                  onChange={(e) => {
                    setFormData({ ...formData, verse: e.target.value });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Explication</label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData({ ...formData, description: e.target.value });
                  }}></textarea>
              </div>
              <div className="form-group">
                <label>Challenge</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Dit à une personne que tu n'aime pas que tu l'aime aujourdhui"
                  value={formData.challenge}
                  onChange={(e) => {
                    setFormData({ ...formData, challenge: e.target.value });
                  }}
                />
              </div>
            </form>
            <button className="btn btn-primary" onClick={() => updateData()}>
              Submit
            </button>
          </Popup>
        </PopupEdit>
      </>
    );
  };

  useEffect(() => {
    onValue(ref(db, `/dataIHM/${category}`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null || data !== undefined) {
        setDataCategory(data);
      }
    });
  }, []);

  const filteredItems = dataCategory.filter(
    (item) => item && item.verse && item.verse.includes(searchTerm)
  );

  const deleteText = () => {
    remove(ref(db, `/dataIHM/${category}/${currentCategoryIndex}`))
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error', error);
      });
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const seletedCurrentCategory = (element, index) => {
    setCurrentCategory(element);
    setCurrentCategoryIndex(index);
    setShowPopup(true);
  };

  return (
    <Container>
      {showModal && (
        <Modal
          cancel={() => setshowModal(false)}
          delete={() => deleteText()}
          text="Souhaitez vous vraiment supprimer ce verset ?"
        />
      )}
      <Header>
        <ButtonClose onClick={() => navigate(-1)}>
          <img src={ArrowImg} alt="arrow" />
        </ButtonClose>
        <h2>{category}</h2>
      </Header>

      {showPopup && (
        <EditElement element={currentCategory} index={currentCategoryIndex} category={category} />
      )}
      {showPopup && <Layout onClick={() => setShowPopup(false)}></Layout>}

      <InputSearch type="text" onChange={handleChange} placeholder="Chercher un verset" />
      <ContainerCard>
        {filteredItems.map((text, index) => (
          <Card
            key={index}
            verse={text.verse}
            content={text.verseText}
            desc={text.verseDescription}
            challenge={text.challenge}
            handleEdit={() => seletedCurrentCategory(text, index)}
            handleDelete={() => {
              setshowModal(true), setCurrentCategoryIndex(index);
            }}
          />
        ))}
      </ContainerCard>
    </Container>
  );
};

export default DashboardDetails;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: #f4f5f8;
  padding: 0px 20px;
  min-height: 100vh;
  @media (min-width: 1200px) {
    padding: 0px 10%;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: start;
  align-items: center;
  h2 {
    margin-left: 75px;
    font-size: 1.563rem !important;
  }
`;

const ContainerCard = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InputSearch = styled.input`
  width: 100%;
  padding: 12px 20px;
  background: white;
  border-radius: 5px;
  border: none;
  margin: 10px 0px;
`;

const ButtonClose = styled.button`
  width: 54px;
  height: 54px;
  border-radius: 54px;
  background: white;
  border: none;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupEdit = styled.div`
  position absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
  width: auto;
  height: auto;
  top: 0px;
  left: 0px;
  background: none;
  
`;

const Layout = styled.div`
  width: 100%;
  height: 100%;
  position absolute;
  background: black;
  opacity: 0.5;
  left: 0px;
  top: 0px;
  z-index: 97;
`;
const Popup = styled.div`
  min-width: 336px;
  width: 50%;
  height: auto;
  background: white;
  z-index: 100;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  form {
    color: black;
  }
  transform: translate(-50%, -50%);
`;
