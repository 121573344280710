import React, { useState, createContext } from 'react';

const JetContext = createContext();

function JetProvider(props) {
  const [User, setUser] = useState({ user: null });

  return <JetContext.Provider value={[User, setUser]}>{props.children}</JetContext.Provider>;
}

export { JetContext, JetProvider };
