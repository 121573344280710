import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function Popup(props) {
  const [topPosition, setTopPosition] = useState(40);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const popupHeight = 60;
      const maxScroll = windowHeight - popupHeight - 40;

      const newTopPosition = Math.min(maxScroll, scrollY + 40);
      setTopPosition(newTopPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getBackgroundColor = () => {
    switch (props.status) {
      case 'error_popup':
        return '#ff8787';
      case 'success_popup':
        return '#dceccb';
      case 'info_popup':
        return '#acdce4';
      default:
        return null;
    }
  };

  const getContent = () => {
    switch (props.status) {
      case 'error_popup':
        return 'Box déjà configurée';
      case 'success_popup':
        return 'Enregistrement de votre box';
      case 'info_popup':
        return 'Votre box a été mise à jour';
      case 'delete':
        return 'Message supprimé';
      default:
        return 'Aucun projet correspondant';
    }
  };

  return (
    <PopupContainer
      className={props.status}
      style={{
        opacity: props.isShow ? 1 : 0,
        top: `${topPosition}px`,
        backgroundColor: getBackgroundColor()
      }}>
      <p>{getContent()}</p>
    </PopupContainer>
  );
}

const PopupContainer = styled.div`
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  position: absolute;
  left: 50%;
  z-index: 100;
  transition: all 0.3s ease-in;
  transform: translate(-50%, -50%);

  p {
    margin: 0;
  }
};
`;
