import React, { useContext } from 'react';
import styled from 'styled-components';
import { JetContext } from '../context/UserContext';
import Button from '../components/Button';
import Input from '../components/Input';
import ProgressBar from '../components/progressBar';
import ArrowButton from '../components/ArrowButton';

const BasicContainer = ({
  question,
  progress,
  children,
  goBack,
  description,
  goNext,
  value,
  onChange,
  placeholder,
  isValid,
  isNextStep,
  typeInput
}) => {
  const [Jets, setJets] = useContext(JetContext);
  console.log('🚀 ~ file: BasicContainer.js:24 ~ setJets:', setJets);
  return (
    <Container style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
      <ArrowButton color={Jets.isDarkMode ? 'white' : 'black'} handleClick={goBack} />
      <ProgressBar progress={progress} />
      <h3>{question}</h3>
      <h4>{description}</h4>
      {children !== undefined ? (
        children
      ) : (
        <Input
          type={typeInput}
          placeholder={placeholder}
          value={value}
          handleChange={onChange}
          isValid={isValid}
        />
      )}
      <Button
        text="Valider"
        bg="#D39E54"
        textColor="white"
        handleClick={goNext}
        isNextStep={isNextStep}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100dvh;
  background-color: white;
  padding-top: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  h3{
    
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400 !important;
    color: #D39E54;
  }
  h4{
    margin-bottom: 5rem;
    font-family: GalanoGrotesque-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    color: #D39E54;
  }
  input {
    margin-bottom: 20rem;
  }

  }
`;

export default BasicContainer;
