import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import Button from '../components/Button';

const HomeContainer = ({ handleSignUp, handleSignIn }) => {
  return (
    <Container>
      <img src={logo} alt="logo" />
      <h2>Plus qu’une box un mode de vie </h2>
      <div>
        <Button text="Me connecter" bg="white" textColor="#D39E54" handleClick={handleSignUp} />
        <Button text="Crée un compte" bg="#D39E54" textColor="white" handleClick={handleSignIn} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-top: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;

  img {
    width: 151px;
    flex-grow: 1;
  }
  h2 {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 3;
    button {
      margin-bottom: 20px;
    }
  }
`;

export default HomeContainer;
