import React, { useEffect, useState } from 'react';
import { ref, update } from 'firebase/database';
import { auth, db } from '../../firebase.js';
import Stripe from 'stripe';
import styled, { keyframes } from 'styled-components';
import Sucessimg from '../../assets/success.png';

function getDateDuJour(number) {
  var date = new Date(); // Crée un objet Date avec la date et l'heure actuelles
  var jour = date.getDate(); // Récupère le jour du mois (entre 1 et 31)
  var mois = date.getMonth() + number; // Récupère le mois (entre 0 et 11) et ajoute 1 pour avoir un mois entre 1 et 12
  var annee = date.getFullYear().toString().substr(-2); // Récupère les deux derniers chiffres de l'année (sous forme de chaîne de caractères)
  var dateDuJour =
    jour.toString().padStart(2, '0') + '/' + mois.toString().padStart(2, '0') + '/' + annee; // Concatène les valeurs pour obtenir la date du jour au format JJ/MM/AA
  return dateDuJour; // Retourne la date du jour
}

const Success = () => {
  const [customerId, setCustomerId] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));

  const fetchCustomerId = async () => {
    const stripe = new Stripe(
      'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
    );
    const customer = await stripe.customers.search({
      query: `email:'${user.email}'`
    });
    setCustomerId(customer.data[0].id);
  };

  useEffect(() => {
    fetchCustomerId();
  }, [customerId]);

  const goToHome = () => {
    if (customerId !== null) {
      update(ref(db, `/users/${auth.currentUser.uid}/user`), {
        subscription: true,
        customerId: customerId,
        startSubscrition: getDateDuJour(1),
        nextDatePayment: getDateDuJour(2)
      });
      window.location.href = '/';
    } else {
      console.log('CUSTOMER ID IS NULL');
    }
  };

  return (
    <SuccessContainer>
      <img src={Sucessimg} alt="success" />
      <ConfettiAnimation />
      <h1>Success</h1>
      <h2>
        Nous sommes extrêmement reconnaissants pour votre abonnement et nous sommes ravis de vous
        savoir parmi nous !
      </h2>
      <Button onClick={() => goToHome()}>Revenir à l’accueil </Button>
    </SuccessContainer>
  );
};

const SuccessContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  img {
    width: 100px;
  }
  h2 {
    text-align: center;
  }
`;

const Button = styled.button`
  width: 200px;
  height: 50px;
  background: #2654e3;
  border-radius: 7px;
  color: white;
  border: none;
`;

// Animation des confettis
const confettiAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
`;

// Style du confetti
const Confetti = styled.div`
  width: 10px;
  height: 10px;
  background-color: #ffdd00;
  position: absolute;
  top: -10px;
  border-radius: 50%;
  animation: ${confettiAnimation} 3s linear infinite;
`;

// Composant d'animation de confettis
const ConfettiAnimation = () => {
  // Générer des confettis aléatoires
  const generateConfetti = () => {
    const confettiElements = [];

    for (let i = 0; i < 100; i++) {
      const left = Math.random() * 100;
      const delay = Math.random() * 3;
      const duration = Math.random() * 4 + 2;

      confettiElements.push(
        <Confetti
          key={i}
          style={{
            left: `${left}%`,
            animationDelay: `${delay}s`,
            animationDuration: `${duration}s`
          }}
        />
      );
    }

    return confettiElements;
  };

  return <>{generateConfetti()}</>;
};

export default Success;
