import React, { useEffect, useState } from 'react';
import { uid } from 'uid';
import styled from 'styled-components';

// FIREBASE
import { set, ref, onValue } from 'firebase/database';
import { auth, db } from '../../firebase.js';

// IMAGE
import PenIcon from '../../assets/penIcon.svg';
import CroceIcon from '../../assets/croseIcon.svg';

export default function FeedUser() {
  const [arrayMessages, setArrayMessages] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [nameUser, setNameUser] = useState(null);

  const [message, setMessage] = useState('');

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setNameUser(user.email);
        onValue(ref(db, `/Feed`), (snapshot) => {
          const data = snapshot.val();
          if (data !== null) {
            let tab = Object.values(data).map((todo) => todo);
            setArrayMessages(tab);

            console.log('🚀 ~ file: FeedUser.js ~ line 31 ~ onValue ~ tab', tab);
          }
        });
      }
    });
  }, []);

  // add
  const writeToDatabase = () => {
    const uidd = uid();
    set(ref(db, `/Feed/${uidd}`), {
      like: 0,
      text: message,
      user: nameUser,
      uidd: uidd
    });
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup ? (
        <Dialog>
          <Popup>
            <img src={CroceIcon} alt="" onClick={() => setShowPopup(false)} />
            <label>Dites nous ce que vous voulez dire ?</label>

            <textarea
              rows="5"
              cols="33"
              placeholder="test message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}></textarea>

            <button onClick={writeToDatabase}>Ajouter</button>
          </Popup>
        </Dialog>
      ) : (
        ''
      )}
      <ViewContainer>
        <h3>Feed</h3>
        {arrayMessages.map((item, key) => {
          return (
            <CardFeed key={key}>
              <h6>{item.user}</h6>
              <p>{item.text}</p>
            </CardFeed>
          );
        })}
        <ButtonWrite onClick={() => setShowPopup(true)}>
          <img src={PenIcon} alt="" />
        </ButtonWrite>
      </ViewContainer>
    </div>
  );
}

const ViewContainer = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
  padding 10px;
  h3{
    text-align: center;
  }
`;

const CardFeed = styled.div`
  width: 100%;
  height: auto;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 3px;
  margin: 10px 0px;
  p {
    margin: 5px 0px;
  }
`;

const ButtonWrite = styled.button`
  width: 63px;
  height: 63px;
  border-radius: 50%;
  background: #2654e3;
  position: fixed;
  bottom: 104px;
  right: 5px;
  border: none;
  color: white;
`;

const Dialog = styled.div`
  width: 100%;
  height: 95vh;
  background: rgba(0, 0, 0, 0.34);
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  width: 338px;
  height: 314px;
  background: #ffffff;
  border-radius: 3px;
  position: relative;
  padding: 20px;
  img {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  textarea {
    width: 100%;
    height: 128px;
    background: #f1f5fe;
    border-radius: 3px;
    border: none;
    margin-top: 10px;
    padding: 5px;
    font-size: 14px;
  }
  label {
    font-weight: 600;
    font-size: 14px;
  }
  button {
    width: 100%;
    height: 42px;
    background: #2654e3;
    border-radius: 3px;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
  }
`;
