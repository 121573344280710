import React, { useState } from 'react';
import styled from 'styled-components';
import ImgEye from '../assets/Eye_light.svg';

const Input = ({ type, value, handleChange, placeholder, isValid }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <>
      {type === 'password' ? (
        <div style={{ position: 'relative', height: '58px', marginBottom: '7rem' }}>
          <InputContainer
            type={isPasswordVisible ? 'text' : 'password'}
            onChange={handleChange}
            value={value}
            style={{ border: isValid ? '1px solid #4CBC14' : 'none' }}
          />
          <img
            src={ImgEye}
            alt="eye"
            style={{ position: 'absolute', bottom: '15px', right: '10px' }}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </div>
      ) : (
        <InputContainer
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          style={{ border: isValid ? '1px solid #4CBC14' : 'none' }}
        />
      )}
    </>
  );
};

const InputContainer = styled.input`
  width: 100%;
  height: 58px;
  border-radius: 7px;
  background-color: var(--secondary-color);
  border: none;
  font-family: GalanoGrotesque-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-left: 10px;
`;

export default Input;
