import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { fetchToken, onMessageListener } from './firebase';
import Notification from './components/Notification';
// import styled from 'styled-components';

// VIEWS
import Homepage from './screens/Homepage';
import AddSensorsUser from './screens/User/AddSensorsUser';
import SensorsUser from './screens/User/SensorsUser';
import FeedUser from './screens/User/FeedUser';
import SettingsUser from './screens/User/SettingsUser';
import AuthentificationUser from './screens/User/AuthentificationUser';
import Dashboard from './screens/Dashboard/Dashboard';
import EventPage from './screens/EventPage';
import SubscriptionPage from './screens/Subscription/SubscriptionPage';
import Cancel from './screens/Subscription/CancelPage';
import Success from './screens/Subscription/SuccesPage';
import Chat from './screens/Subscription/Chat';
import DashboardDetails from './screens/Dashboard/DashboardDetails';
import DetailsChallenge from './screens/DetailsChallenge';
import EventDetails from './screens/EventDetails';

import { JetProvider } from './context/UserContext';

const AnimatedRoutes = () => {
  let location = useLocation();
  return (
    <JetProvider>
      <AnimatePresence initial={false} exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" exact element={<AuthentificationUser />} />
          <Route path="/homepage" exact element={<Homepage />} />
          <Route path="/add" exact element={<AddSensorsUser />} />
          <Route path="/feed" exact element={<FeedUser />} />
          <Route path="/event" exact element={<EventPage />} />
          <Route path="/settings" exact element={<SettingsUser />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/dashboard/:category" exact element={<DashboardDetails />} />
          <Route path="/subscription" exact element={<SubscriptionPage />} />
          <Route path="/cancel" exact element={<Cancel />} />
          <Route path="/success" exact element={<Success />} />
          <Route path="/sensors/:id" element={<SensorsUser />} />
          <Route path="/challenge/:category/:number" element={<DetailsChallenge />} />
          <Route path="/event/:nameEvent" element={<EventDetails />} />
          <Route path="/chat" exact element={<Chat />} />
        </Routes>
      </AnimatePresence>
    </JetProvider>
  );
};

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body
      });
      setShow(true);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <div className="app">
      {show && <Notification show={show} notification={notification} setShow={setShow} />}
      <header className="app__header">
        {!isTokenFound ? (
          <></>
        ) : (
          // <Banner>
          //   <h4>
          //     Veuillez accepter les notification pour ne pas rater votre challenge quotidien ❗️
          //   </h4>
          // </Banner>
          ''
        )}
      </header>
      <Router>
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;

// const Banner = styled.div`
//   width: 100%;
//   height: auto;
//   padding: 10px;
//   background-color: rgba(255, 255, 255, 0.95);
//   @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
//     -webkit-backdrop-filter: blur(10px);
//     backdrop-filter: blur(10px);
//     background-color: rgba(255, 255, 255, 0.5);
//   }
//   z-index: 1000000000000;

//   position: fixed;
//   top: 0px;
//   text-align: center;
//   border-radius: 5px;
// `;
