import React from 'react';
import styled from 'styled-components';
import editIcon from '../assets/edit.svg';
import deleteIcon from '../assets/delete_dashboard.svg';

const Card = ({ verse, content, desc, challenge, handleEdit, handleDelete }) => {
  return (
    <CardContainer>
      <div>
        <CardHeading>Verset</CardHeading>
        <ButtonContainer>
          <ActionButton style={{ backgroundColor: '#F9BB26' }} onClick={handleEdit}>
            <img src={editIcon} alt="Modifier" />
          </ActionButton>
          <ActionButton style={{ backgroundColor: '#C81625' }} onClick={handleDelete}>
            <img src={deleteIcon} alt="Supprimer" />
          </ActionButton>
        </ButtonContainer>
      </div>
      <CardText>{verse}</CardText>
      <CardHeading>Contenu</CardHeading>
      <CardText>{content}</CardText>
      <CardHeading>Description</CardHeading>
      <CardText>{desc}</CardText>
      <CardHeading>Challenge</CardHeading>
      <CardText>{challenge}</CardText>
    </CardContainer>
  );
};

export default Card;

const CardContainer = styled.div`
  width: 100%;
  max-width: 386px;
  height: auto;
  padding: 10px;
  display: flex;
  margin: 20px 0px;
  flex-direction: column;
  background: #ffffff;
  border-radius: 5px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h5 {
    font-size: 1.3rem !important;
    color: #2654e3;
  }

  p {
    font-size: 0.95rem !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 28px;
    height: 28px;
    border-radius: 3px;
    border: none;
    margin: 0px 7px;
  }
`;

const ActionButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 3px;
  border: none;
  margin: 0px 7px;
`;

const CardHeading = styled.h5`
  font-size: 0.875rem !important;
  color: #2654e3;
`;

const CardText = styled.p`
  font-size: 0.75rem !important;
`;
