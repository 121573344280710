import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase.js';
import { ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import ArrowButton from '../components/ArrowButton.js';
import { JetContext } from '../context/UserContext.js';

export default function DetailsChallenge() {
  let { category, number } = useParams();
  const [countChallengeFail] = useState(Math.floor(Math.random() * 101));
  const [countChallengeDone] = useState(Math.floor(Math.random() * 101));
  const [data, setData] = useState(null); // [data, setData
  const navigate = useNavigate();
  const [Jets] = useContext(JetContext);

  useEffect(() => {
    onValue(ref(db, `dataIHM/${category}/${number}`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null || data !== undefined) {
        setData(data);
      }
    });
  }, []);

  return (
    <Container style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
      <ArrowButton handleClick={() => navigate(-1)} color={Jets.isDarkMode ? 'white' : 'black'} />
      <h3 style={{ textAlign: 'center', margin: 0, color: Jets.isDarkMode ? 'white' : 'black' }}>
        Informations
      </h3>
      <h3 style={{ color: Jets.isDarkMode ? 'white' : 'black' }}>Challenge</h3>
      <Card>
        <h4>{category}</h4>
        <h2>{data?.challenge}</h2>
      </Card>
      <h3 style={{ color: Jets.isDarkMode ? 'white' : 'black' }}>Statistiques</h3>
      <CardStatsContainer>
        <CardStats>
          <h1>{countChallengeDone}</h1>
          <p>Réussis le challenge</p>
        </CardStats>
        <CardStats>
          <h1>{countChallengeFail}</h1>
          <p>Retenter le challenge</p>
        </CardStats>
        <CardStats>
          <h1>{countChallengeFail + countChallengeDone}</h1>
          <p>À avoir fait le challenge sur votre box</p>
        </CardStats>
      </CardStatsContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100dvh;
  padding: 20px;
  h3{
    margin-top: 40px;
    color: Jets.isDarkMode ? 'white' : 'black';
  }
  }
`;

const Card = styled.div`
  width: 100%;
  height: auto;
  padding: 30px;
  border-radius: 31px;
  background: linear-gradient(157deg, #fcf2e4 20.54%, #f1d3aa 96.6%);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const CardStatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CardStats = styled.div`
  padding: 10px;
  border-radius: 17px;
  background: #fcf2e4;
  flex-grow: 1;
  margin-top: 9px;
  margin-right: 5px;
`;
