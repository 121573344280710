import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, Suspense, useRef, useContext } from 'react';
import styled from 'styled-components';
import { db, auth } from '../../firebase.js';
import { ref, onValue, update, set, remove } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import ArrowButton from '../../components/ArrowButton.js';

import { motion } from 'framer-motion';
import Stripe from 'stripe';
import { JetContext } from '../../context/UserContext.js';

import Modal from '../Modal.js';
import Popup from '../../components/Popup.js';

import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import proto3D from '../../assets/proto1.fbx';

import Button from '../../components/Button.js';

// ASEETS
import bookImg from '../../assets/user_sensors/book.svg';
import checkImg from '../../assets/user_sensors/check.svg';
import echecImg from '../../assets/user_sensors/Dell.svg';
import platineImg from '../../assets/user_sensors/platine.svg';
import bronzeImg from '../../assets/user_sensors/bronze.svg';
import silverImg from '../../assets/user_sensors/silver.svg';
import goldImg from '../../assets/user_sensors/gold.svg';

const Model = () => {
  const fbx = useLoader(FBXLoader, proto3D);

  const ref = useRef();

  return (
    <>
      <mesh ref={ref} position={[0, -70, 0]} scale={[1.5, 1.5, 1.5]} rotation={[10, 0, 0]}>
        <primitive object={fbx} />
        {/* <meshStandardMaterial color="#ff0000" opacity={0.1} shininess={50} /> */}
      </mesh>
    </>
  );
};

export default function SensorPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [filter] = useState('');
  const [category, setCategory] = useState('success');
  const [selectedOption, setSelectedOption] = useState('');
  const [idSubscription, setIdSubscription] = useState('');
  const [sensorsUsers, setSensorsUsers] = useState([]);
  const [user_data, setUser_data] = useState([]);
  const [isModisSensors, setIsModifsSensors] = useState(false);
  const [listChallengeFailed, setListChallengeFailed] = useState([]);
  const [listChallengeSucced, setListChallengeSucced] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const scrollableDivRef = useRef(null);
  const [Jets] = useContext(JetContext);

  useEffect(() => {
    onValue(ref(db, `/sensors/`), (snapshot) => {
      setSensorsUsers([]);
      const data = snapshot.val();
      Object.values(data).map((item) => {
        if (item.id === id) {
          setSensorsUsers(item);
          setSelectedOption(item.category);
          const list = item.listChallenge || {};
          setListChallengeFailed(Object.values(list));
          setListChallengeSucced(Object.values(list));
        }
      });
    });

    auth.onAuthStateChanged((user) => {
      if (user) {
        // read user
        onValue(ref(db, `/users/${auth.currentUser.uid}`), (snapshot) => {
          const data = snapshot.val();
          if (data !== null) {
            setUser_data(data.user);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    const fetchCustomerId = async () => {
      const stripe = new Stripe(
        'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
      );
      const subscriptions = await stripe.subscriptions.list();

      const findSubscriptionCustomer = subscriptions.data.find(
        (x) => x.customer === user_data.customerId
      );
      setIdSubscription(findSubscriptionCustomer?.id);
    };
    fetchCustomerId();
  }, [user_data]);

  useEffect(() => {}, [user_data, selectedOption]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  async function cancelSubscription(subscriptionId) {
    const stripe = new Stripe(
      'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
    );
    try {
      const subscription = await stripe.subscriptions.update(subscriptionId, {
        cancel_at_period_end: true
      });
      console.log("L'abonnement a été résilié avec succès :", subscription.id);
      return subscription;
    } catch (error) {
      console.error("Une erreur est survenue lors de la résiliation de l'abonnement :", error);
      throw error;
    }
  }

  const filteredList = listChallengeSucced.filter(
    (item) =>
      item.state.toLowerCase().includes(filter.toLowerCase()) &&
      (category === '' || item.state === category)
  );

  const goToSubscription = () => {
    navigate('/subscription');
  };

  //Filter user's scores
  const getNumberChallengeFailed = () => {
    let list = listChallengeFailed.filter((item) => item.state === 'failed');
    return list.length;
  };

  const getNumberChallengeSucced = () => {
    let list = listChallengeSucced.filter((item) => item.state === 'success');
    return list.length;
  };

  const handleGradeUserText = () => {
    if (getNumberChallengeFailed() + getNumberChallengeSucced() <= 300) {
      return 'Bronze';
    } else if (getNumberChallengeFailed() + getNumberChallengeSucced() >= 300) {
      return 'Argent';
    } else if (getNumberChallengeFailed() + getNumberChallengeSucced() >= 5000) {
      return 'Or';
    } else if (getNumberChallengeFailed() + getNumberChallengeSucced() >= 10000) {
      return 'Platine';
    }
  };

  const handleGradeUserImage = () => {
    if (getNumberChallengeFailed() + getNumberChallengeSucced() <= 300) {
      return bronzeImg;
    } else if (getNumberChallengeFailed() + getNumberChallengeSucced() >= 300) {
      return silverImg;
    } else if (getNumberChallengeFailed() + getNumberChallengeSucced() >= 5000) {
      return goldImg;
    } else if (getNumberChallengeFailed() + getNumberChallengeSucced() >= 10000) {
      return platineImg;
    }
  };

  const deleteBox = () => {
    remove(ref(db, `/users/${auth.currentUser.uid}/user/sensors/${sensorsUsers.key}`)).then(() => {
      console.log('DELETE BOX', sensorsUsers.id, 'KEY', sensorsUsers.key);
    });

    remove(ref(db, `/users/${auth.currentUser.uid}/user/startSubscrition/`))
      .then(() => {
        console.log('Delete sub', sensorsUsers.id, 'KEY', sensorsUsers.key);
      })

      .catch((error) => {
        console.log('Error', error);
      });
    set(ref(db, `/sensors/${sensorsUsers.id}/`), {
      id: sensorsUsers.id,
      is_appear: false,
      is_online: true
    });

    update(ref(db, `/users/${auth.currentUser.uid}/user`), {
      subscription: false
    });

    if (idSubscription !== undefined) {
      cancelSubscription(idSubscription)
        .then((subscription) => {
          console.log("L'abonnement a été résilié avec succès :", subscription.id);
          update(ref(db, `/users/${auth.currentUser.uid}/user`), {
            subscription: false
          });
          navigate('/homepage');
        })
        .catch((error) => {
          console.error("Une erreur est survenue lors de la résiliation de l'abonnement :", error);
        });
    } else {
      navigate('/homepage');
    }
  };

  const pageMotion = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: 20, transition: { duration: 0.5 } }
  };

  // async function reactivateSubscription(subscriptionId) {
  //   const stripe = new Stripe(
  //     'sk_test_51MgTATKww5u0tgAGJjsLFA2mrVDXBlKsal9xaPz8OShrKhOojqj6K8gHufZcDMyViYCodYF8ZqJ0jEFQwyuuzQlM00zloO7hw7'
  //   );

  //   try {
  //     const subscription = await stripe.subscriptions.update(subscriptionId, {
  //       cancel_at_period_end: false
  //     });
  //     console.log("L'abonnement a été réactivé avec succès :", subscription.id);
  //     return subscription;
  //   } catch (error) {
  //     console.error("Une erreur est survenue lors de la réactivation de l'abonnement :", error);
  //     throw error;
  //   }
  // }

  return (
    <>
      {showPopup && (
        <Modal
          cancel={() => setShowPopup(false)}
          delete={() => deleteBox()}
          text="Souhaitez vous supprimer la box"
        />
      )}
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageMotion}
        ref={scrollableDivRef}>
        <Popup status="info_popup" ref={scrollableDivRef} isShow={isModisSensors} />
        <ViewContainer style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
          <ArrowButton
            handleClick={() => navigate(-1)}
            color={Jets.isDarkMode ? 'white' : 'black'}
          />
          <Canvas
            minZoom={false}
            maxZoom={false}
            camera={{ position: [70, 70, 70] }}
            style={{ minHeight: `auto`, zIndex: 10, height: '300px' }}
            orthographic={true}>
            <Suspense fallback={null}>
              <Model />
            </Suspense>
            <ambientLight intensity={0.1} />
            <directionalLight position={[5, 0, 10]} intensity={0.5} />
            <directionalLight position={[0, 5, 0]} intensity={0.5} />
            <directionalLight position={[5, 0, 0]} intensity={0.5} />
            <OrbitControls
              minZoom={1.1}
              makeDefault
              autoRotate={true}
              maxZoom={1.3}
              enablePan={false}
              enableZoom={false}
            />
          </Canvas>
          <>
            <CardDetails>
              <Card>
                <img src={checkImg} alt="" />
                <div>
                  <h3 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>
                    {getNumberChallengeSucced()}
                  </h3>
                  <p style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Challenges réussis</p>
                </div>
              </Card>
              <Card>
                <img src={echecImg} alt="" />
                <div>
                  <h3 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>
                    {getNumberChallengeFailed()}
                  </h3>
                  <p style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Challenges échoués</p>
                </div>
              </Card>
              <Card>
                <img src={handleGradeUserImage()} alt="" />
                <div>
                  <h3 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>
                    {handleGradeUserText()}
                  </h3>
                  <p style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Division</p>
                </div>
              </Card>
              <Card>
                <img src={bookImg} alt="" />
                <div>
                  <h3>{getNumberChallengeFailed() + getNumberChallengeSucced()}</h3>
                  <p style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Challenge faits</p>
                </div>
              </Card>
            </CardDetails>
            <ContainerInput>
              <h3>Renommer la box</h3>
              <TextInput
                type="name"
                placeholder="name"
                value={sensorsUsers.name}
                onChange={(e) => {
                  setSensorsUsers({
                    ...sensorsUsers,
                    name: e.target.value
                  });
                }}
              />
            </ContainerInput>
            <ContainerInput>
              <h3 style={{ color: Jets.isDarkMode ? 'white' : '#262626' }}>Ma categorie</h3>
              <div>
                {user_data.subscription === false && <TextInput value={sensorsUsers.category} />}
                {user_data.subscription === true && (
                  <SelectCategory
                    defaultValue={sensorsUsers.category}
                    onChange={handleOptionChange}>
                    <option value="relationel">Relationel</option>
                    <option value="motivation">Motivation</option>
                    <option value="santé">Santé</option>
                    <option value="mental">Mental</option>
                  </SelectCategory>
                )}
              </div>
            </ContainerInput>

            {user_data.customerId === undefined && (
              <ContainerInput>
                <Button
                  className="sign-in-register-button"
                  bg="#2654E3"
                  text="Changer ma catégorie"
                  handleClick={() => goToSubscription()}
                />
              </ContainerInput>
            )}

            {user_data.subscription === true && (
              <ContainerInput>
                <h3>Discutez avec ma box</h3>
                <ContainerButton
                  onClick={() => navigate('/chat', { state: { id: sensorsUsers.id } })}>
                  Discutez
                </ContainerButton>
              </ContainerInput>
            )}

            <ContainerInput>
              <h3>Mes Challenges</h3>
              <ContainerChallenge>
                <div>
                  <button
                    onClick={() => setCategory('success')}
                    style={{
                      fontWeight: category === 'success' ? 'bold' : '',
                      color: category === 'success' ? '#D39E54' : '#868686'
                    }}>
                    Réussis
                  </button>
                  <button
                    onClick={() => setCategory('failed')}
                    style={{
                      fontWeight: category === 'failed' ? 'bold' : '',
                      color: category === 'failed' ? '#D39E54' : '#868686'
                    }}>
                    Échoués
                  </button>
                </div>
                <ul>
                  {filteredList.map((item, index) => (
                    <li key={index}>{item.length === 0 ? 'null' : item.challenge}</li>
                  ))}
                  {filteredList.length === 0 && <li>Aucun challenge</li>}
                </ul>
              </ContainerChallenge>
            </ContainerInput>
            <ContainerInput>
              <Button
                bg="#D39E54"
                textColor="white"
                text="Mettre à jour les données"
                handleClick={() => {
                  update(ref(db, `/sensors/${sensorsUsers.id}/`), {
                    name: sensorsUsers.name,
                    category: selectedOption
                    // routine: sensorsUsers.routine,
                    // categories: sensorsUsers.categories
                  });
                  update(ref(db, `/users/${auth.currentUser.uid}/user`), {
                    name: sensorsUsers.name
                    // routine: sensorsUsers.routine,
                    // categories: sensorsUsers.categories
                  });

                  setIsModifsSensors(true);
                  setTimeout(() => {
                    setIsModifsSensors(false);
                  }, 3500);
                }}
              />
            </ContainerInput>
            <ContainerInput>
              <Button
                bg="#C81625"
                handleClick={() => setShowPopup(true)}
                text="Supprimer la box"
                textColor="white"
              />
            </ContainerInput>
          </>
        </ViewContainer>
      </motion.div>
    </>
  );
}
const ContainerChallenge = styled.div`
  width: 100%;
  height: auto;
  background: #fcf2e4;
  border-radius: 7px;
  font-family: GalanoGrotesque-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  div {
    width: 100%;
    button {
      width: 50%;
      border: none;
      font-weight: 400;
      font-size: 16px;
      color: #868686;
      border-bottom: 1px solid #868686;
      background: none;
      padding: 10px 0px;
    }
  }
  ul {
    width: 100%;
    padding: 10px;
    li {
      list-style: none;
      padding: 15px 0px;
    }
    border-bottom: 1px solid #868686;
  }
`;

const SelectCategory = styled.select`
  width: 100%;
  height: 49px;
  background: #f1f5fe;
  border-radius: 7px;
  border: none;
`;
const ViewContainer = styled.div`
  height: auto;
  width: 100vw;
  position: relative;
  padding: 10px 0px;
  h3 {
    margin: 0px;
    font-size: 16px;
    color: ${window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'white'
      : '#262626'};
  }
  @media only screen and (min-width: 912px) {
    padding: 0% 23%;
  }
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-item: center;
  flex-direction: column;
  h1 {
    text-align: center;
    margin: 20px 0px;
  }
  .imgBox {
    align-self: center;
  }
`;

const CardDetails = styled.div`
  width: 100%;
  height: 113px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Card = styled.div`
  max-width: 170px;
  flex-grow: 1;
  height: 49px;
  border: 1px solid #868686;
  border-radius: 7px;
  display: flex;
  padding: 2px;
  margin: 5px;

  p {
    font-size: 12px;
  }
  img {
    width: 17px;
    margin-right: 3px;
    align-self: baseline;
  }
`;

const ContainerButton = styled.button`
  width: 100%;
  height: 54px;
  background: #f1f5fe;
  text-align: left;
  padding-left: 10px;
  border: none;
  border-radius: 7px;
`;

const ContainerInput = styled.div`
  padding: 9px;
  margin: 4px;
  display: flex;
  flex-direction: column;
`;

const TextInput = styled.input`
  width: 100%;
  height: 52px;
  border: none;
  background: #fcf2e4;
  border-radius: 7px;
  font-size: 16px;
  margin: 10px 0px;
  padding-left: 5px;
  font-family: GalanoGrotesque-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
