import React from 'react';
import styled from 'styled-components';

// Style du message de paiement refusé
const PaymentErrorMessage = styled.div`
  background-color: #c81625;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
`;

// Composant de message de paiement refusé
const PaymentError = () => {
  return (
    <PaymentErrorMessage>
      <h3>Un problème est survenu</h3>
      <p>Une erreur est survenue lors de l’achat de votre abonnement</p>
    </PaymentErrorMessage>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled.button`
  width: 200px;
  height: 50px;
  background: #2654e3;
  border-radius: 7px;
  color: white;
  border: none;
  margin-top: 20px;
`;

// Utilisation du composant de message de paiement refusé
const App = () => {
  return (
    <Container>
      <PaymentError />
      <Button onClick={() => (window.location.href = '/homepage')}>Revenir à l'accueil</Button>
    </Container>
  );
};

export default App;
