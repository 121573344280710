import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/NavBar';
import { auth, db } from '../firebase.js';
import { ref, onValue } from 'firebase/database';
import { JetContext } from '../context/UserContext.js';

export default function EventPage() {
  const [userData, setUserData] = useState([]);
  const [monthNames] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]);
  const [eventData] = useState([
    {
      img: require('../assets/img_events/sinach.png'),
      startDate: '2023-11-12',
      endDate: '2023-11-13',
      title: 'Concert Sinach',
      location: '7 rue isaac newton, blanc mesnil',
      nameEvent: 'concert-sinach',
      description:
        'Le dimanche 12 novembre, nous aurons l’honneur de recevoir pour la toute première fois Sinach (@therealsinach), à l’occasion de la célébration d’anniversaire de notre merveilleuse église. 🎂 Sa musique a touché des millions de personnes en apportant la guérison, l’espoir, l’encouragement et la fortification de leur foi ! Alors ne manquez pas ce rendez-vous divin et soyez prêts pour une expérience avec Dieu, qui peut changer votre vie à tout jamais ! ',
      prenium: true
    },
    {
      img: require('../assets/img_events/bar.png'),
      startDate: '2023-11-10',
      endDate: '2023-11-11',
      title: 'Bar à jeux chrétien',
      nameEvent: 'Bar-à-jeux-chrétien',
      description:
        'Hello tout le monde ! Bienvenue à tous ceux qui nous rejoignent 🤗 A l’occasion de la reprise des sorties, nous vous proposons une soirée dans un bar à jeux !  🎲 On vous donne *_rendez-vous le vendredi 10 novembre à 19h._*🕖 ',
      location: 'Les Mauvais Joueurs Austerlitz 12 boulevard Vincent Auriol, 75013 Paris',
      prenium: false
    },
    {
      img: require('../assets/img_events/concert.png'),
      startDate: '2023-11-04',
      endDate: '2023-11-05',
      title: 'Temps de patage entre jeunes',
      nameEvent: 'Temps-de-patage-entre-jeunes',
      description:
        "Vivez une journée exceptionnelle au croisement de la foi et du rythme au concert Célébration Divine. Des artistes chrétiens de renom fusionnent les beats entraînants du kompas rap avec l'énergie festive du coupé-décalé. Rejoignez-nous pour une expérience unique mêlant adoration, danse et inspiration, créant ainsi une atmosphère de joie et d'élévation spirituelle. Billets disponibles maintenant. Soyez prêts à célébrer la foi d'une manière innovante et passionnante !",
      location: '1 avenue Bourdelle, 93600 Aulnay-sous-Bois',
      prenium: false
    },
    {
      img: require('../assets/img_events/reveil.png'),
      startDate: '2023-31-10',
      endDate: '2023-11-04',
      title: 'LA COJERAF',
      nameEvent: 'la-cojeraf',
      description:
        'LET’S GO 💥 ! LA COJERAF EST DE RETOUR du 31 Octobre au 04 Novembre 2023 🔥 ! Et cette année est très particulière car la convention des jeunes rafraîchis et affranchis fête ses 10 ANS 🎂🎉! Ce soir, on vous dévoile le thème « VIVRE LE RÉVEIL DANS UNE NOUVELLE ÈRE » 🌪️',
      location: 'Église parole du salut',
      prenium: false
    },
    {
      img: require('../assets/img_events/event2.jpg'),
      startDate: '2023-04-08',
      endDate: '2023-04-09',
      title: 'Chasse aux oeuf',
      nameEvent: 'Chasse-aux-oeuf',
      description:
        "Joignez-vous à notre chasse aux œufs de Pâques chrétienne ! Une journée de divertissement en famille, remplie d'activités ludiques, de chansons joyeuses et d'une quête amusante d'œufs décorés avec des symboles de foi. Rejoignez-nous dans l'esprit de la résurrection pour une aventure festive et spirituelle. Venez célébrer Pâques d'une manière spéciale avec nous !",
      location: 'au parc de la cité les Vaucrises 02400 Château-Thierry',
      prenium: false
    },
    {
      img: require('../assets/img_events/event3.png'),
      startDate: '2023-04-08',
      endDate: '2023-04-09',
      title: 'Pure festi Gospel',
      nameEvent: 'Pure-festi-Gospel',
      description:
        "Plongez dans l'émotion pure et la spiritualité d'un concert chrétien captivant. Rejoignez-nous pour une soirée de louange vibrante et de musique envoûtante interprétée par des artistes talentueux. Des mélodies inspirantes et des paroles édifiantes vous attendent. Un moment de communion et de célébration de la foi.",
      location: 'Blanc Mesnil',
      prenium: false
    }
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [Jets] = useContext(JetContext);

  useEffect(() => {
    setIsLoading(false);
  }, [eventData]);

  useEffect(() => {
    console.log('🚀 ~ file: EventPage.js:54 ~ EventPage ~ Jets:', Jets);
    auth.onAuthStateChanged((user) => {
      if (user) {
        // read user
        onValue(ref(db, `/users/${auth.currentUser.uid}`), (snapshot) => {
          const data = snapshot.val();
          console.log('🚀 ~ file: EventPage.js:64 ~ onValue ~ data:', data);
          if (data !== null) {
            setUserData(data.user);
          }
        });
      }
    });
  }, []);

  useEffect(() => {}, [userData]);

  function handleDate(date) {
    let parts = date.split('-');
    let day = parts[2];
    return day;
  }

  function handleMonth(date) {
    let parts = date.split('-');
    let monthNumber = parseInt(parts[1], 10);
    let monthName = monthNames[monthNumber - 1];
    return monthName;
  }

  return (
    <ViewContainer style={{ backgroundColor: Jets.isDarkMode ? '#262626' : 'white' }}>
      <h1
        style={{
          color: Jets.isDarkMode ? 'white' : '#262626',
          backgroundColor: Jets.isDarkMode ? '#262626' : 'white'
        }}>
        Événement
      </h1>
      <div
        className="view_container_info"
        style={{
          backgroundColor: Jets.isDarkMode ? '#4F4F4F' : '#F5F5F5',
          color: Jets.isDarkMode ? 'white' : '#262626'
        }}>
        <p>Retrouvez la liste de tout les événement chrétiens partout en france</p>
      </div>
      <AllCardEvent>
        {isLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          eventData.map((data, key) => (
            <CardEvent
              key={key}
              style={{ backgroundColor: Jets.isDarkMode ? '#4F4F4F' : '#F5F5F5' }}>
              <img src={data.img} alt="" />
              <span>
                <h2>{handleDate(data.startDate)}</h2>
                <p>{handleMonth(data.startDate)}</p>
              </span>
              <div className="container_element">
                <button onClick={() => navigate(`/event/${data.nameEvent}`)}>
                  Afficher les détails{' '}
                </button>
              </div>
            </CardEvent>
          ))
        )}
      </AllCardEvent>
      <Navbar
        link_homepage={() => navigate('/homepage')}
        link_event={() => navigate('/event')}
        link_compte={() => navigate('/settings')}
        link_dashboard={() => navigate('/dashboard')}
        dataUser=""
        navigation="event"
      />
    </ViewContainer>
  );
}

const ViewContainer = styled.div`
  height: auto;
  width: 100vw;
  position: relative;
  padding: 10px;
  padding-top: 80px;
  padding-bottom: 100px;
  h1 {
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0;
    position: fixed;
    z-index: 10;
    top: 0px;
  }

  p {
    margin: 0;
  }

  .view_container_info {
    width: 100%;
    height: auto;
    padding: 5px;
    text-align: center;
    background: #f5f5f5;
    border-radius: 3px;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 912px) {
    padding-top: 90px;
    ul {
      position: fixed;
      top: 0px;
      background: none;
    }
  }
`;

const Skeleton = styled.div`
  width: 100%;
  height: 201px;
  border-radius: 7px;
  background-color: #c9c8c8;
  margin: 10px;
  animation: pulse 1s infinite ease-in-out;
  @keyframes pulse {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const AllCardEvent = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
`;

const CardEvent = styled.div`
  width: 100%;
  height: auto;
  border-radius: 7px;
  position: relative;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  span {
    width: 50px;
    height: 49px;
    border-radius: 7px;
    background: #FCF2E4;
    position: absolute;
    top: 5px;
    right: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #d39e54;
    h2, p {
      line-height: 17px !important;
      margin: 0;
    }

  }
  .container_element{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    border-radius: 0px 0px 5px 5px;
    background: #FCF2E4;
    button{
      width: 100%;
      height: 45px;
      border-radius: 3px;
      background: #D39E54;
      border: none;
      color: white;
      font-family: GalanoGrotesque-Medium;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
    }
    span{
      width: 93px;
      height: 45px;
      border-radius: 3px;
      text-align: center;
      line-height: 45px;
      color: white;
    }
  }
  @media only screen and (min-width: 912px) {
    width: 338px;
  }
  img{
    z-index:-10
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    resize: cover;
    border-radius: 7px;
    &.loading {
      visibility: hidden; /* Masque l'image lorsqu'elle n'est pas encore chargée */
    }
  }
  .days{
    width: 50px;
    height: 49px;
    background: #FFFFFF;
    border-radius: 7px;
    position: absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    top: 5px;
    right:5px;
    p, h2{
      margin:0
    }
  }
  .hours{
    position: absolute;
    bottom: 0px;
    right:5px;
    color: white;
  }
  .info{
    position: absolute;
    bottom: 0px;
    left:5px;
    color: white;
    p{
      font-size: 12px;
    }
  }
`;
