import React from 'react';
import styled from 'styled-components';
import ArrowWhite from '../assets/Arrow-white.svg';
import ArrowBlack from '../assets/Arrow-black.svg';

const ArrowButton = ({ color, handleClick }) => {
  return (
    <ArrowButtonContainer onClick={handleClick}>
      {color === 'white' ? (
        <img src={ArrowWhite} alt="arrow" />
      ) : (
        <img src={ArrowBlack} alt="arrow" />
      )}
    </ArrowButtonContainer>
  );
};

const ArrowButtonContainer = styled.button`
  width: auto;
  height: auto;
  position: absolute;
  background: none;
  border: none;
  top: 20px;
  left: 30px;
  z-index: 100;
`;

export default ArrowButton;
